<template>
  <div class="app">
    <Header @lang-change="langChange"/>
    <router-view :lang="lang" />
    <Footer :lang="lang" />
  </div>
</template>

<script>

import Header from '@/components/Header';
import Home from '@/components/Home';
import Footer from '@/components/Footer';

export default {
  name: 'App',
  data() {
    return {
      lang: 'СРБ'
    }
  },
  methods: {
    langChange(lang) {
      this.lang = lang;
    }
  },
  mounted() {
  },
  components: {
    Home,
    Header,
    Footer,
  }
}
</script>


