
<template>
  <div id="biginfoBottom" class="biginfo shadow-main mb-5 mt-5" style="margin-bottom:2rem;">
    <div class="najbolji">
      <div class="content-background display-only-desktop">
          <h2 v-html="translate.title[lang]"></h2>
          <p class="first">{{translate.line1[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
      </div>
    </div>
    <div class="content-background-mobile-najbolji display-only-phone">
          <h2 v-html="translate.title[lang]"></h2>
          <p class="first">{{translate.line1[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Najpouzdaniji',
  props: ['lang'],
   data() {
    return {
      translate: {
        title: {
          EN: 'THE BEST SYSTEM <br><span>for regulated traffic</span>',
          LAT: 'NAJBOLJI SISTEM<br><span>za uređen saobraćaj</span>',
          СРБ: 'НАЈБОЉИ СИСТЕМ<br><span>за уређен саобраћај</span>',
          DE: 'DAS BESTE SYSTEM<br><span>für geregelten Verkehr</span>',
        },
        line1: {
          EN: 'With an automated process, a vehicle cannot "escape". Every license plate, every violation, and unpaid parking, while the system does not pause and works continuously..',
          LAT: 'Uz automatizovan proces ne postoji mogućnost da neko vozilo "pobegne". Beleži se svaka registarska oznaka, svaki prekršaj, neplaćeno parkiranje, nema „opraštanja“ niti „dogovora“, a sistem ne pravi pauzu i radi kontinuirano.',
          СРБ: 'Уз аутоматизован процес не постоји могућност да неко возило "побегне". Бележи се свака регистарска ознака, сваки прекршај, неплаћено паркирање, нема „опраштања“ нити „договора“, а систем не прави паузу и ради континуирано.',
          DE: 'Dem automatisierten Prozess kann keines Fahrzeug "entkommen". Jedes Nummernschild, jede Verletzung und unbezahltes Parken sind bezeichnet – und das System arbeitet kontinuierlich ohne Pause.',
        },
        line2: {
          EN: 'The potentials of this kind of traffic regulation system are innumerable, and some of the most important is solving the problem of potholes, illegal gardens, traffic signs, control of placed advertisements, as well as expertise of accidents. The improvement of the system and the introduction of new functionalities will follow, which will expand the scope of work.',
          LAT: 'Potencijali sistema su nebrojeni, a neki od najznačajnijih su rešavanje problema rupa na putu, nelegalnih bašti, saobraćajnih znakova, kontrola postavljenih reklama, kao i veštačenje udesa. Sledi unapređenje sistema i uvođenje novih funkcionalnosti, kojima će biti proširen delokrug rada.',
          СРБ: 'Потенцијали система су небројени, а неки од најзначајнијих су решавање проблема рупа на путу, нелегалних башти, саобраћајних знакова, контрола постављених реклама, као и вештачење удеса. Следи унапређење система и увођење нових функционалности, којима ће бити проширен делокруг рада.',
          DE: 'Die Potenziale dieser Art von Verkehrsregelungssystem sind zahllos, und einige der wichtigsten umfassen die Lösung des Problems von Schlaglöchern, illegalen Gärten, Straßenschilder, die Kontrolle von platzierter Anzeigen, sowie die Expertise von Unfällen. Die Verbesserung des Systems und die Einführung neuer Funktionalitäten werden folgen, wodurch sich der Arbeitsumfang erweitern wird.',
        }
      }
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      this.checkInfoView();
    });
    window.addEventListener('scroll', function(e) {
        self.checkInfoView();
    });
  },
  methods: {
    checkInfoView() {
      if (this.isInViewport(document.getElementById('biginfoBottom'))) {

        document.getElementById("biginfoBottom").classList.add("active");

      }
    },
    isInViewport(elem) {

        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );

    }
  }
}
</script>