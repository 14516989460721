<template>
  <div class="footer">
    <div class="container d-none">
      <img class="ikonica-mobile" src="/images/mail.svg" alt="">
    <div>
      <img class="ikonica-desktop" src="/images/mail.svg" alt="">
      <p><a href="mailto:info@parking-servis.co.rs" target="_blank">info@parking-servis.co.rs</a></p>
    </div>
    <div>
      <p><a href="mailto:beoinfo@beograd.gov.rs" target="_blank">beoinfo@beograd.gov.rs</a></p>
    </div>
    <div class="mr-0">
            <p><a href="mailto:komunalna.milicija@beograd.gov.rs" target="_blank">komunalna.milicija@beograd.gov.rs</a></p>
    </div>
    </div>
    <div class="container d-block">
      <div class="row">
        <div class="col-12 col-md-8 mx-auto mt-3 mt-lg-5 text-center justify-content-center">
          <div class="images-group">
            <img src="/images/f1.png" alt="">
            <img src="/images/f2.png" alt="">
            <img class="mr-0 pr-0" src="/images/f3.png" alt="">
          </div>
        </div>
        <div class="col-12 col-md-8 mx-auto text-center justify-content-center mt-0 mt-lg-5">
            <div class="text-center"><p class="small">Parking Servis © Copyright 2021 | All Rights Reserved</p></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Footer',
  props: ['lang'],
  data() {
    return {
      footer: {
        address: {
          EN: 'Kneza Višeslava 27',
          LAT: 'Kneza Višeslava 27',
          СРБ: 'Кнеза Вишеслава 27',
        }
      }
    }
  }
}
</script>