<template>
  <div id="digitalizacija" class="biginfo shadow-main mb-5">
    <div class="digitalizacija">
      <div class="content-background">
          <h2 v-html="translate.title[lang]"></h2>
          <p>{{translate.line1[lang]}}</p>
          <p class="mb-0">{{translate.lineMid[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Digitalizacija',
  props: ['lang'],
  data() {
    return {
      translate: {
        title: {
          EN: 'SYSTEM DIGITALIZATION <br><span>parking fee</span>',
          LAT: 'DIGITALIZACIJA SISTEMA<br><span>naplate parkinga</span>',
          СРБ: 'ДИГИТАЛИЗАЦИЈА СИСТЕМА<br><span>наплате паркинга</span>',
          DE: 'SYSTEMDIGITALISIERUNG<br><span>Parkgebühren</span>'
        },
        line1: {
          EN: 'With the implementation of innovative technologies, the Parking service has switched to electronic business in numerous segments of its activity and introduced the so-called parking. As part of it, an application * was launched, through which users contactless pay for parking in zones, garages, and parking lots.',
          LAT: 'Implementacijom inovativnih tehnologija, Parking servis je u brojnim segmentima svoje delatnosti prešao ne elektronsko poslovanje i uveo tzv. eParking. U sklopu njega, puštena je u rad i aplikacija*, putem koje korisnici beskontaktno plaćaju parkiranje u zonama, garažama i na parkiralištima.',
          СРБ: 'Имплементацијом иновативних технологија, Паркинг сервис је у бројним сегментима своје делатности прешао не електронско пословање и увео тзв. еПаркинг. У склопу њега, пуштена је у рад и апликација*, путем које корисници бесконтактно плаћају паркирање у зонама, гаражама и на паркиралиштима.',
          DE: 'Durch die Durchführung innovativer Technologien hat der Parkplatzdienst in zahlreichen Segmenten seiner Tätigkeit auf den elektronischen Geschäftsverkehr umgesattelt und das sogenannte eParken eingeführt. Als Teil davon wurde eine Anwendung* eingeführt, mit der die Benutzer kontaktlos für das Parken in Zonen, Garagen und auf Parkplätzen bezahlen.',
        },
        line2: {
          EN: 'The goal of this application is to automate the work process, reduce the possibility of error or oversight, as well as faster and more efficient information processing.',
          LAT: 'Cilj ove aplikacije je automatizacija procesa rada, manja mogućnost za greške ili previde, kao i brža i efikasnija obrada informacija.',
          СРБ: 'Циљ ове апликације је аутоматизација процеса рада, мања могућност за грешке или превиде, као и бржа и ефикаснија обрада информација.',
          DE: 'Das Ziel dieser Anwendung ist es, den Arbeitsprozess zu automatisieren, die Möglichkeit von Fehlern oder Versehen zu reduzieren sowie eine schnellere und effizientere Informationsverarbeitung zu ermöglichen.',
        },
        lineMid: {
          EN: 'With the application, faster and easier data processing is possible for all users of the Parking service: daily parking tickets, privileged parking tickets, "baby" parking tickets, and disability parking tickets',
          LAT: 'Uz aplikaciju je moguća brža i lakša obrada podataka za sve korisnike usluga Parking servisa: dnevne parking karte, povlašćene parking karte, „bebi“ parking karte, invalidske parking karte.',
          СРБ: 'Уз апликацију је могућa бржа и лакша обрада података за све кориснике услуга Паркинг сервиса: дневне паркинг карте, повлашћене паркинг карте, „беби“ паркинг карте, инвалидске паркинг карте.',
          DE: 'Mit der Anwendung ist die schnellere und einfachere Datenverarbeitung für alle Benutzer des Parkplatzdienst ermöglicht: Tagesparkscheine, privilegierte Parkscheine, "Baby"-Parkscheine und Behindertenparkscheine.',
        }
      }
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      this.checkInfoView();
    });
    window.addEventListener('scroll', function(e) {
        self.checkInfoView();
    });
  },
  methods: {
    checkInfoView() {
      if (this.isInViewport(document.getElementById('digitalizacija'))) {

        document.getElementById("digitalizacija").classList.add("active");

      }
    },
    isInViewport(elem) {

        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );

    }
  }
}
</script>