<template>
  <div class="info" id="info">
    <div class="container">
      <div class="row">
         <div class="col-6 col-md-3">
          <a href="https://parking-servis.co.rs/edpk/" target="_blank">
            <div  :class="['new-box', {'active': sel===8}]" @click="sel=8">
              <div class="image">
                <img src="/images/i7.svg" alt="">
              </div>
              <div class="new-text">
                <h3 v-html="info.desk.col8[lang]"></h3>
              </div>
            </div>
          </a>
        </div>
         <div class="col-6 col-md-3">
          <a href="https://parking-servis.co.rs/garaze-i-parkiralista#6-slobodna-parking-mesta" target="_blank">
            <div  :class="['new-box', {'active': sel===3}]" @click="sel=3">
              <div class="image">
                <img src="/images/i2.svg" alt="">
              </div>
              <div class="new-text">
                <h3 v-html="info.desk.col5[lang]"></h3>
              </div>
            </div>
          </a>
        </div>
         <div class="col-6 col-md-3">
          <a href="https://parking-servis.co.rs/aplikacija" target="_blank">
            <div  :class="['new-box', {'active': sel===6}]" @click="sel=6">
              <div class="image">
                <img src="/images/i5.svg" alt="">
              </div>
              <div class="new-text">
                <h3 v-html="info.desk.col6[lang]"></h3>
              </div>
            </div>
          </a>
        </div>
         <div class="col-6 col-md-3">
          <a href="https://eparking.rs/login" target="_blank">
            <div  :class="['new-box', {'active': sel===5}]" @click="sel=5" style="transform:translateY(10px)">
              <div class="image">
                <img src="/images/i4.svg" alt="">
              </div>
              <div class="new-text">
                <h3 v-html="info.col3Title[lang]" style="transform:translateY(-10px)"></h3>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Info',
  props: ['lang'],
  mounted() {
  },
  data() {
    return{
      sel: null,
      info: {
        col3Title: {
          EN: 'E PARKING',
          LAT: 'E PARKING',
          СРБ: 'Е ПАРКИНГ',
          DE: 'Elektronisches Parken'
        },
        desk: {
          col1: {
            EN: 'E-daily parking ticket',
            DE: 'E-daily parking ticket',
            LAT: 'e-Dnevna parking karta',
            СРБ: 'е-Дневна паркинг карта',
          },
          col2: {
            EN: 'List of streets  by zones',
            DE: 'List of streets  by zones',
            LAT: 'Pretraga ulica po zonama',
            СРБ: 'Претрага улица по зонама',
          },
          col3: {
            EN: 'ePPK, Prepaid card <span class="font-italic">all services at one place',
            DE: 'ePPK, Prepaid card <span class="font-italic">all services at one place',
            LAT: 'E PARKING',
            СРБ: 'Е ПАРКИНГ ',
          },
          col4: {
            EN: 'Zone system  subscription',
            DE: 'Zone system  subscription',
            LAT: 'Mesečna pretplata  u zonama',
            СРБ: 'Месечна претплата  у зонама',
          },
          col5: {
            EN: 'Available parking spaces',
            DE: 'Freie Plätze',
            LAT: 'Slobodna mesta',
            СРБ: 'Слободна места',
          },
          col6: {
            EN: 'Application',
            DE: 'Anwendung',
            LAT: 'Aplikacija',
            СРБ: 'Апликација',
          },
          col7: {
            EN: 'Prepaid parking card',
            DE: 'Prepaid parking card',
            LAT: 'Pripejd kartica',
            СРБ: 'Припејд картица',
          },
          col8: {
            EN: 'Subscription for garages and car parks',
            DE: 'Elektronisches tägliches Parkticket',
            LAT: 'E- DNEVNA PARKING KARTA',
            СРБ: 'Е - ДНЕВНА ПАРКИНГ КАРТА',
          },
          col9: {
            EN: 'Park and cycle',
            DE: 'Park and cycle',
            LAT: 'Parkiraj i bicikliraj',
            СРБ: 'Паркирај и бициклирај',
          },
          col10: {
            EN: 'Parking Pass',
            DE: 'Parking Pass',
            LAT: 'Parking Pass',
            СРБ: 'Паркинг Pass',
          },
        },
        mob: {
          col1: {
            EN: 'E-daily parking  ticket',
            DE: 'E-daily parking  ticket',
            LAT: 'e-Dnevna parking karta',
            СРБ: 'е-Дневна паркинг карта',
          },
          col2: {
            EN: 'List of streets  by zones',
            DE: 'List of streets  by zones',
            LAT: 'Pretraga ulica po zonama',
            СРБ: 'Претрага улица по зонама',
          },
          col3: {
            EN: 'ePPK, Prepaid card <span class="font-italic">all services at one place',
            DE: 'ePPK, Prepaid card <span class="font-italic">all services at one place',
            LAT: 'ePPK, Pripejd kartica <span class="font-italic">Vaše usluge na jednom mestu',
            СРБ: 'еППК, Припејд картица <span class="font-italic">Ваше услуге на једном месту',
          },
          col4: {
            EN: 'Zone  system  subscription',
            EDEN: 'Zone  system  subscription',
            LAT: 'Mesečna pretplata u zonama',
            СРБ: 'Месечна претплата у зонама',
          },
          col5: {
            EN: 'Available parking  spaces',
            DE: 'Available parking  spaces',
            LAT: 'Slobodna mesta',
            СРБ: 'Слободна места',
          },
          col6: {
            EN: 'Application',
            DE: 'Application',
            LAT: 'Aplikacija',
            СРБ: 'Апликација',
          },
          col7: {
            EN: 'Prepaid parking card',
            DE: 'Prepaid parking card',
            LAT: 'Pripejd kartica',
            СРБ: 'Припејд картица',
          },
          col8: {
            EN: 'Subscription   for garages  and car parks',
            DE: 'Subscription   for garages  and car parks',
            LAT: 'Pretplate u   garažama i na   parkiralištima',
            СРБ: 'Претплате у   гаражама и на   паркиралиштима',
          },
          col9: {
            EN: 'Park and  cycle',
            DE: 'Park and  cycle',
            LAT: 'Parkiraj i bicikliraj',
            СРБ: 'Паркирај и бициклирај',
          },
          col10: {
            EN: 'Parking Pass',
            DE: 'Parking Pass',
            LAT: 'Parking Pass',
            СРБ: 'Паркинг ПАСС',
          },
        }
      }
    }
  },
  methods: {
  },
}

</script>