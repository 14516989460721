<template>
  <div class="hero shadow-main" id="hero">
  <!-- <video preload="auto" loop autoplay playsinline muted src="/vids/promo1.mp4" class="background-video"></video> -->
    <div class="mask-hero-video"></div>
    <div class="hero__content">
      <div class="pp">
        <h1 v-html="hero.title[lang]"></h1>
        <div class="line-hero"></div>
        <p class="hero-p" v-html="hero.html[lang]"></p>
      </div>
    </div>
    <!-- <div class="video-component" v-show="playVideo">
      <div class="mask-video" @click="playVideo=false"></div>
      <img class="close" src="../assets/close.png" @click="playVideo=false" alt="">
      <div class="video-holder-lb">
          <video ref="heroVideoH" :class="['hero-vid']" controls playsinline preload="auto" src="/vids/promoo.mp4" allowfullscreen="allowfullscreen"></video>
      </div>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'Hero',
  props: ['lang'],
  mounted() {
  },
  data() {
    return{
      playVideo: false,
      hero: {
        title: {
          EN: 'OKO <br>SOKOLOVO',
          LAT: 'OKO <br>SOKOLOVO',
          СРБ: 'ОКО <br>СОКОЛОВО',
          DE: 'OKO <br>SOKOLOVO'
        },
        html: {
          EN: 'Oko sokolovo (Falcon’s eye) - a unique system of specialized vehicles that serves for more effective traffic control, successfully controls proper and improper parking in Belgrade.',
          LAT: 'Oko sokolovo – jedinstveni sistem specijalizovanih vozila koji obavlja efektivniju kontrolu saobraćaja i, dokazano, uspešno kontroliše propisno i nepropisno parkiranje u Beogradu.',
          СРБ: 'Око соколово – јединствени систем специјализованих возила који обавља ефективнију контролу саобраћаја и, доказано, успешно контролише прописно и непрописно паркирање у Београду.',
          DE: 'Das Falkenauge – das einzigartige System von Spezialfahrzeuge, das eine effektivere Verkehrskontrolle durchführt und das ordnungsgemäßes und widerrechtliches Parken in Belgrad erfolgreich kontrolliert'
        },
      }
    }
  },
  methods: {
    play(poster) {
      this.playVideo=true;
      this.$refs.heroVideoH.setAttribute('poster', poster);
      this.$refs.heroVideoH.play();
    }
  },
}

</script>