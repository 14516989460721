<template>
  <div id="sectionmap" class=" mb-5">
    <!-- <div class="digitalizacija">
      <div class="content-background">
          <h2 v-html="translate.title[lang]"></h2>
          <p>{{translate.line1[lang]}}</p>
          <p class="mb-0">{{translate.lineMid[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
      </div>
    </div> -->
    <div class="row">
      <div class="col-12 col-md-6 mapcontent">
        <h2 class="font-weight-bold" v-html="translate.title[lang]"></h2>
        <h2 class="font-italic mb-4" v-html="translate.line1[lang]"></h2>
        <p v-html="translate.line2[lang]"></p>
      </div>
      <div class="col-12 col-md-6 mapserbia"></div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'SectionMap',
  props: ['lang'],
  data() {
    return {
      translate: {
        title: {
          EN: 'Oko sokolovo',
          LAT: 'Oko sokolovo',
          СРБ: 'Око соколово',
          DE: 'Falkenauge (Oko Sokolovo)'
        },
        line1: {
          EN: 'across Serbia',
          LAT: 'u Srbiji',
          СРБ: 'у Србији',
          DE: 'in Serbien',
        },
        line2: {
          EN: 'Oko sokolovo, the innovative system widely acclaimed for its success in Belgrade, is proud to be taking on a new challenge - maintaining communal order on the streets of Niš and Zlatibor starting 2022.',
          LAT: 'Nakon uspešne implementacije sistema u Beogradu, Oko sokolovo, od 2022. godine, brine o održavanju komunalnog reda na ulicama Niša i Zlatibora.',
          СРБ: 'Након успешне имплементације система у Београду, Око соколово, од 2022. године, брине о одржавању комуналног реда на улицама Ниша и на Златибору.',
          DE: 'Nach der erfolgreichen Implementierung des Systems in Belgrad, sorgt seit 2022 das Falkenauge über Instandhaltung der Kommunalordnung auf den Strassen von Nis und Zlatibor.',
        },
      }
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      this.checkInfoView();
    });
    window.addEventListener('scroll', function(e) {
        self.checkInfoView();
    });
  },
  methods: {
    checkInfoView() {
      if (this.isInViewport(document.getElementById('sectionmap'))) {

        document.getElementById("sectionmap").classList.add("active");

      }
    },
    isInViewport(elem) {

        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );

    }
  }
}
</script>