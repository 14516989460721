<template>
  <div class="videos" id="videos" ref="videos">
    <h3>{{trans[lang]}}</h3>
    <div class="inner-vids">
      <!--div>
        <div class="video-wrap hide-mobile">
          <div :class="['video-holder', {'width-60': index===0 || index===3, 'width-40': index===1 || index===2 }]" v-for="(vid, index) in items" :key="`vids-desk-${index}`">
            <img class="video-background" :src="vid.piclocation" alt="">
            <img class="play" @click="play(vid.vidlocation, vid.piclocation, vid.type)" src="/images/play.svg" alt="">
            <div class="video-mask"></div>
          </div>
      </div>
    <div-->
      <div class="video-component" v-show="playVideo && type=='mp4'">
        <div class="mask-video" @click="playVideo=false"></div>
        <img class="close" src="../assets/close.png" @click="playVideo=false" alt="">
        <div class="video-holder-lb">
            <video ref="vids" :class="['hero-vid']" controls playsinline preload="auto" src="/vids/promoo.mp4" allowfullscreen="allowfullscreen"></video>
        </div>
      </div>
      <div class="video-component" v-show="playVideo && type=='yt'">
        <img class="close" src="../assets/close.png" @click="playVideo=false" alt="">
        <div class="video-holder-lb">
          <iframe ref="heroVideo" v-if="playVideo && type=='yt'" :class="['hero-vid']" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <div class="row new-slider" :style="translateCarousel">
        <div :class="['col-6 col-md-4 p-0 new-slider-slide', {'new-slider-slide-center': index === slideTo}]" v-for="(item, index) in items" :key="`slider-item-${index}`" @click="setSlideTo(index)">
          <div class="slider-wrapper">
            <div class="video-slider" :style="{ backgroundImage: 'url(' + item.piclocation + ')' }"></div>
            <img v-if="index === slideTo" class="play" src="/images/play.svg" @click="play(item.vidlocation, item.piclocation, item.type)" alt="">
          </div>
        </div>
      </div>
      <div class="slider-nav">
        <span v-for="(item, index) in items" @click="setSlideTo(index)" :key="`new-slider-buttons-${index}`" :class="['slider-nav-button', {'selected-slider-nav-button': index === slideTo}]"></span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Videos',
  props: ['lang'],
  computed: {
    translateCarousel() {
      return `transform: translateX(${ this.slideToPx }px)`;
    },
  },
  data() {
    return {
      trans: {
        EN: 'VIDEO',
        DE: 'VIDEO',
        LAT: 'VIDEO',
        СРБ: 'ВИДЕО',
      },
      srbVids: [
            { piclocation:"https://i.ytimg.com/vi_webp/ema2lYOIAOs/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/ema2lYOIAOs", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/rY-7QsmDTTU/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/rY-7QsmDTTU", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/Qkbf2y-RVl4/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/Qkbf2y-RVl4", type: 'yt' },
      ],
      engVids: [
            { piclocation:"https://i.ytimg.com/vi_webp/mgBCsE96yGQ/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/mgBCsE96yGQ", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/4sWupeDA8Oc/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/4sWupeDA8Oc", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/flf16nGxkWw/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/flf16nGxkWw", type: 'yt' },
      ],
      gerVids: [
            { piclocation:"https://i.ytimg.com/vi_webp/mgBCsE96yGQ/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/mgBCsE96yGQ", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/LkSHu1UjAN8/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/LkSHu1UjAN8", type: 'yt' },
            { piclocation:"https://i.ytimg.com/vi_webp/flf16nGxkWw/hqdefault.webp", vidlocation: "https://www.youtube.com/embed/flf16nGxkWw", type: 'yt' },
      ],
      sliderFactor: 0,
      type: null,
      slideTo: 1,
      playVideo: false,
      slideToPx: 0,
      items: [],
      xDown: null,
      yDown: null
    }
  },
  mounted() {
    let self = this;
    this.setTranslateCarousel();

    window.onresize = function() {
      self.slideTo = 1;
      setTimeout(() => {self.setTranslateCarousel();}, 300);

      document.addEventListener('touchstart', self.handleTouchStart, false);
      document.addEventListener('touchmove', self.handleTouchMove, false);

    };
    this.items = this.srbVids;
    if(self.playVideo === true) {
      consolel.log('video')
    }
  },
  methods: {
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
        const firstTouch = this.getTouches(evt)[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    },
    handleTouchMove(evt) {
        if ( ! this.xDown || ! this.yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        let max = this.items.length;
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                if (this.slideTo+1 <max)
                  this.setSlideTo(this.slideTo+1)
            } else {
                if (this.slideTo-1>=0)
                  this.setSlideTo(this.slideTo-1)
            }
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        this.setTranslateCarousel();
        /* reset values */
        this.xDown = null;
        this.yDown = null;
    },
    setTranslateCarousel() {
      let self = this;
      let element = document.getElementsByClassName('new-slider-slide');
      this.sliderFactor = 0;
      if (window.matchMedia("only screen and (max-width: 576px)").matches) {
        this.$nextTick(() => {
          self.sliderFactor = element[0].offsetWidth/2 + 30 + this.slideTo * 60;
          if (!element || !element.length)
            return this.slideToPx = 0 - this.sliderFactor;
          let width = element[0].offsetWidth;
          this.slideToPx = -(this.slideTo-1) * width - this.sliderFactor;
        });
      } else {
        if (!element || !element.length)
          return this.slideToPx = 0 - this.sliderFactor;
        let width = element[0].offsetWidth;
        this.slideToPx = -(this.slideTo-1) * width - this.sliderFactor;
      }
    },
    setSlideTo(index) {
      this.slideTo = index;
      this.setTranslateCarousel();
    },
    play(url, poster, type) {
      this.type = type;

      this.playVideo=true;

      if (this.type == 'yt') {
            this.$nextTick(()=> {
                this.$refs.heroVideo.setAttribute('src', url);
            });
      }

      if (this.type == 'mp4') {
        this.$nextTick(()=> {
          this.$refs.vids.setAttribute('poster', poster);
          this.$refs.vids.setAttribute('src', url);
          this.$refs.vids.play();
        });
      }

    }
  },
  watch: {
    playVideo: function() {
      if (this.playVideo && this.type == 'mp4')
        this.$refs.vids.play();
          else
            this.$refs.vids.pause();
    },
    'lang': function() {
      if (this.lang === 'СРБ') {
          this.items = this.srbVids;
      } else if (this.lang === 'LAT') {
          this.items = this.srbVids;
      } else if (this.lang === 'EN') {
          this.items = this.engVids;
      } else if (this.lang === 'DE') {
          this.items = this.gerVids;
      }
    }
  }
}
</script>