<template>
  <div class="potencijal" id="potencial">
    <div class="potencijal__content container">
        <div class="row videos-pot" id="videoTrans" :style="translateVids">
            <div class="col-12 bg">
                <div class="text-center out-mobile">
                  <video class="potencijal-mobile" src="/vids/OkoSokolovo01_1.mp4" loop muted ref="animVideo1" playsinline preload="auto" allowfullscreen="0"></video>
                </div>
            </div>
            <div class="col-12 bg">
                <div class="text-center out-mobile">
                  <video class="potencijal-mobile" src="/vids/OkoSokolovo02_1.mp4" loop muted ref="animVideo2" playsinline preload="auto" allowfullscreen="0"></video>
                </div>
            </div>
            <div class="col-12 bg">
                <div class="text-center out-mobile">
                  <video class="potencijal-mobile" src="/vids/OkoSokolovo03_1.mp4" loop muted ref="animVideo3" playsinline preload="auto" allowfullscreen="0"></video>
                </div>
            </div>
            <div class="col-12 bg">
                <div class="text-center out-mobile">
                  <video class="potencijal-mobile" src="/vids/OkoSokolovo04_1.mp4" loop muted ref="animVideo4" playsinline preload="auto" allowfullscreen="0"></video>
                </div>
            </div>
        </div>
    </div>
    <div class="container trmob">
      <div class="row algtop" id="algtop" :style="translate">
        <div v-if="selected!==1" class="shadows left"></div>
        <div v-if="selected!==4" class="shadows right"></div>
        <div class="boxx" :style="translateBox"><div class="inner-boxed"></div></div>
        <div class="col-6 col-md-3">
          <div :class="['potencijal-box', {'start' : selected===1}]" @click="selected=1; addSelected()">
            <h4 v-html="trans.top.row1[lang]"></h4>
            <p>{{trans.bottom.col1[lang]}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div :class="['potencijal-box', {'start' : selected===2}]" @click="selected=2; addSelected()">
            <h4 v-html="trans.top.row2[lang]"></h4>
            <p>{{trans.bottom.col2[lang]}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div :class="['potencijal-box', {'start' : selected===3}]" @click="selected=3; addSelected()">
            <h4 v-html="trans.top.row3[lang]"></h4>
            <p>{{trans.bottom.col3[lang]}}</p>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div :class="['potencijal-box', {'start' : selected===4}]" @click="selected=4; addSelected()">
            <h4 v-html="trans.top.row4[lang]"></h4>
            <p>{{trans.bottom.col4[lang]}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Potencijal',
  props: ['lang'],
  computed: {
    translate() {
      return `transform: translateX(${ this.xValue }px)`;
    },
    translateBox() {
      return `transform: translateX(${ this.xValueBox }px)`;
    },
    translateVids() {
      return `transform: translateX(${ this.xValueVids }px)`;
    }
  },
  data() {
    return {
      xDown: null,
      yDown: null,
      xValue: 0,
      xValueBox: 10,
      xValueVids: 10,
      init: true,
      selected: 0,
      trans: {
        top: {
          row1: {
            EN: 'FAST <br>RECOGNITION',
            LAT: 'BRZO <br>PREPOZNAVANJE',
            СРБ: 'БРЗО <br>ПРЕПОЗНАВАЊЕ',
            DE: 'SCHNELLE <br>ERKENNUNG',
          },
          row2: {
            EN: 'OBSERVATION WITHOUT <br>ERRORS',
            LAT: 'OPAŽANJE BEZ<br>GREŠKE',
            СРБ: 'ОПАЖАЊЕ БЕЗ<br>ГРЕШКЕ',
            DE: 'FEHLERFREIE <br>BEOBACHTUNG',
          },
          row3: {
            EN: 'TAKING PHOTOS TABLE<br>',
            LAT: 'FOTOGRAFISANJE TABLICA<br>',
            СРБ: 'ФОТОГРАФИСАЊЕ ТАБЛИЦА<br>',
            DE: 'AUFNAHME VON NUMMERNSCHILD<br>',
          },
          row4: {
            EN: 'DUAL <br>SYSTEM',
            LAT: 'DUALNI <br>SISTEM',
            СРБ: 'ДУАЛНИ<br>СИСТЕМ',
            DE: 'DUALES <br>SYSTEM',
          }
        },
        bottom: {
          col1: {
            EN: 'The most efficient system for recognizing improperly parked vehicles.',
            LAT: 'Najefikasniji sistem za prepoznavanje nepropisno parkiranih vozila.',
            СРБ: 'Најефикаснији систем за препознавање непрописно паркираних возила.',
            DE: 'Das effizienteste System zur Erkennung von widerrechtlich abgestellten Fahrzeugen.',
          },
          col2: {
            EN: 'The system photographs the license plates of properly parked vehicles.',
            LAT: 'Sistem prepoznaje tablice bez greške, uz najbržu analizu registarske oznake, zone, GPS lokacije i vremena opažanja.',
            СРБ: 'Систем препознаје таблице без грешке, уз најбржу анализу регистарске ознаке, зоне, ГПС локације и времена опажања.',
            DE: 'Das System erkennt Schilder ohne Fehler, mit der schnellsten Analyse von Nummernschild, Zone, GPS-Ortung und Beobachtungszeit.',
          },
          col3: {
            EN: 'The system captures photos of all parking violations.',
            LAT: 'Sistem fotografiše registarske tablice propisno parkiranih vozila.',
            СРБ: 'Систем фотографише регистарске таблице прописно паркираних возила.',
            DE: 'Das System fotografiert die Nummernschilder von ordnungsgemäß abgestellten Fahrzeugen.',
          },
          col4: {
            EN: 'Data on irregular parking are sent to the Communal Police, and the Parking Service checks whether the service for properly parked vehicles has been paid for.',
            LAT: 'Podaci o nepropisnom parkiranju šalju se Komunalnoj policiji, a Parking servis proverava da li je plaćena usluga za propisno parkirana vozila.',
            СРБ: 'Подаци о непрописном паркирању шаљу се Комуналној полицији, а Паркинг сервис проверава да ли је плаћена услуга за прописно паркирана возила.',
            DE: 'Daten über widerrechtlich Parken werden an die Kommunalmiliz gesendet und der Parkplatzdienst prüft, ob die Leistung für ordnungsgemäß abgestellte Fahrzeuge bezahlt wurde.',
          }
        }
      }
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      this.checkInfoView();
      this.checkInfoViewVid();
    });
    this.selected = 0;
    window.addEventListener('scroll', function(e) {
      self.checkInfoView();
      self.checkInfoViewVid();
    });

    let init = document.getElementsByClassName('potencijal-box');
    init[0].click();
    window.addEventListener('resize', function() {
      if (window.matchMedia("only screen and (min-width: 736px)").matches) self.xValue = 0;
      self.selected = 0;
      self.selected = 1;
    });

    document.addEventListener('touchstart', self.handleTouchStart, false);
    document.addEventListener('touchmove', self.handleTouchMove, false);
  },
  methods: {
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
        const firstTouch = this.getTouches(evt)[0];
        this.xDown = firstTouch.clientX;
        this.yDown = firstTouch.clientY;
    },
    checkInfoViewVid() {
      if (this.isInViewport(document.getElementById('potencial'))) {

        document.getElementById("potencial").classList.add("active");

        this.init = true;

      }

    },
    handleTouchMove(evt) {

        if ( !this.xDown || !this.yDown ) return;

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.xDown - xUp;
        var yDiff = this.yDown - yUp;

        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */
                if (this.selected<4) this.selected++;
                let addClass = document.getElementsByClassName('start');
                let i;
                setTimeout(() => {
                  for (i = 0; i < addClass.length; i++) {
                    addClass[i].classList.add('selected');
                  }
                }, 100);
            } else {
                /* right swipe */
                if (this.selected>1) this.selected--;
                let addClass = document.getElementsByClassName('start');
                let i;
                setTimeout(() => {
                  for (i = 0; i < addClass.length; i++) {
                    addClass[i].classList.add('selected');
                  }
                }, 100);
            }
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        this.xDown = null;
        this.yDown = null;
    },
    addSelected() {

      let removeElems = document.getElementsByClassName('potencijal-box');
      let addClass = document.getElementsByClassName('start');
      let i;

      for (i = 0; i < removeElems.length; i++) {
        removeElems[i].classList.remove('selected');
      }

      setTimeout(() => {
        for (i = 0; i < addClass.length; i++) {
          addClass[i].classList.add('selected');
        }
      }, 100);

    },
    checkInfoView() {
      if (this.isInViewport(document.getElementById('potencial'))) {

        document.getElementById("potencial").classList.add("visible");

      }
    },
    isInViewport(elem) {

        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );

    }
  },
  watch: {
    'selected': function() {

      if (this.selected == 1) {

        this.$refs.animVideo1.currentTime = 0;
        this.$refs.animVideo1.play();
        this.xValueVids = 0;

        } else if (this.selected == 2) {

          this.$refs.animVideo2.currentTime = 0;
          this.$refs.animVideo2.play();

          this.xValueVids = -document.getElementById("videoTrans").offsetWidth;


        } else if (this.selected == 3) {

          this.$refs.animVideo3.currentTime = 0;
          this.$refs.animVideo3.play();

          this.xValueVids = -document.getElementById("videoTrans").offsetWidth * 2;

        } else if (this.selected == 4) {

          this.$refs.animVideo4.currentTime = 0;
          this.$refs.animVideo4.play();

          this.xValueVids = -document.getElementById("videoTrans").offsetWidth * 3;

        }

      if (window.matchMedia("only screen and (max-width: 574px)").matches) {

        if (this.selected == 1) {
          this.xValueBox = 10;
        } else if (this.selected == 2) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth/2;
        } else if (this.selected == 3) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth;
        } else if (this.selected == 4) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth + document.getElementById("algtop").offsetWidth/2;
        }

      } else if (window.matchMedia("only screen and (min-width: 768px)").matches && window.matchMedia("only screen and (max-width: 992px)").matches) {

        if (this.selected == 1) {
          this.xValueBox = 0;
        } else if (this.selected == 2) {
          this.xValueBox = document.getElementById("algtop").offsetWidth/4;
        } else if (this.selected == 3) {
          this.xValueBox = document.getElementById("algtop").offsetWidth/2;
        } else if (this.selected == 4) {
          this.xValueBox = document.getElementById("algtop").offsetWidth * 3/4;
        }

      } else if (window.matchMedia("only screen and (min-width: 768px)").matches) {

        if (this.selected == 1) {
          this.xValueBox = 0;
        } else if (this.selected == 2) {
          this.xValueBox = document.getElementById("algtop").offsetWidth/4;
        } else if (this.selected == 3) {
          this.xValueBox = document.getElementById("algtop").offsetWidth/2;
        } else if (this.selected == 4) {
          this.xValueBox = document.getElementById("algtop").offsetWidth * 3/4;
        }

      } else {
        if (this.selected == 1) {
          this.xValueBox = 10;
        } else if (this.selected == 2) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth/2;
        } else if (this.selected == 3) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth;
        } else if (this.selected == 4) {
          this.xValueBox = 10 + document.getElementById("algtop").offsetWidth + document.getElementById("algtop").offsetWidth/2;
        }
      }

      if (window.matchMedia("only screen and (min-width: 768px)").matches) return;

      if (this.selected == 1) {
        this.xValue = 0;
      } else if (this.selected == 2) {
        this.xValue = -window.innerWidth/4;
      } else if (this.selected == 3) {
        this.xValue = -window.innerWidth*3/4;
      } else if (this.selected == 4) {
        this.xValue =- window.innerWidth/2 - window.innerWidth + window.innerWidth/4;
      }

    }
  }
}
</script>