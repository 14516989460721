<template>
  <div class="home">
    <Hero :lang="lang" />
    <Model :lang="lang" />
    <Najpouzdaniji :lang="lang"/>
    <Potencial :lang="lang" />
    <Digitalizacija :lang="lang"/>
    <Najbolji :lang="lang" />
    <NewSection :lang="lang" />
    <SectionMap :lang="lang" />
    <Videos :lang="lang" />
    <Info :lang="lang" />
  </div>
</template>

<script>

import Hero from '@/components/Hero';
import Model from '@/components/Model';
import Potencial from '@/components/Potencial';
import Videos from '@/components/Videos';
import Info from '@/components/Info';
import Digitalizacija from '@/components/Digitalizacija';
import Najpouzdaniji from '@/components/Najpouzdaniji';
import Najbolji from '@/components/Najbolji';
import NewSection from '@/components/NewSection'
import SectionMap from '@/components/SectionMap'


export default {
  name: 'Home',
  props: ['lang'],
  components: {
    Hero,
    Model,
    Najpouzdaniji,
    Potencial,
    Najbolji,
    NewSection,
    Videos,
    Digitalizacija,
    Info,
    SectionMap
  },
  mounted() {
  },
  data() {
    return{
    }
  },
  methods: {
  },
}

</script>