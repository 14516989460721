<template>
  <div class="model" id="model">
    <div class="row">
      <div class="col text-center car-model-out">
        <img class="img-holder" src="../assets/empty.png" alt="">
        <div class="model-car">
          <div id="v3d-container" class="text-center"></div>
        </div>
      </div>
    </div>
    <div class="outer-info m-auto">
      <div class="container-sm p-0">
        <div id="infoRow" class="row m-0 info-row justify-content-center">
          <div class="info-col col-6 col-lg-3 plr-7">
            <div class="info-num pb-4"><span id="persecfoto"></span><img class="num-img" src="../assets/camera.svg" alt=""></div>
            <div class="info-text" v-html="model.html.col1[lang]"></div>
            <hr>
          </div>
          <div class="info-col col-6 col-lg-3 plr-7 sec-in">
            <div class="info-num pb-4"><span id="perseccam2"></span><img class="num-img" src="../assets/ikona2.svg" alt=""></div>
            <div class="info-text" v-html="model.html.col2[lang]"></div>
            <hr>
          </div>
          <div class="info-col col-6 col-lg-3 plr-7 third-in">
            <div class="info-num pb-4"><span id="nonstop"></span><img class="num-img" src="../assets/stopwatch.svg" alt=""></div>
            <div class="info-text" v-html="model.html.col3[lang]"></div>
            <hr>
          </div>
          <div class="info-col col-6 info-col-last col-lg-3 plr-7">
            <div class="info-num pb-4"><span id="ppperhIrir"></span><img class="num-img" src="../assets/stopwatch.svg" alt=""></div>
            <div class="info-text" v-html="model.html.col4[lang]"></div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { CountUp } from 'countup.js';

var numAnim1;
var numAnim2;
var numAnim3;
var numAnim4;

export default {
  name: 'Model',
  props: ['lang'],
  mounted() {

    let self = this;

    cam_text = '•	Четири инфрацрвенe камере високе резолуције, са циљем утврђивања садржаја регистарских таблица (све врсте карактера: слова и бројеви)';
    in_text = '•	индустријски рачунар за рад у отежаним радним условима<br>•	ГПС уређај<br>•	комуникациони модул за рад у мрежи мобилног oператора';
    back_text = '•	напајање са мотора возила и/или батерија<br>•	интегрисане хардверске компоненте заштићене од механичких и климатских услова, као и од електричних сметњи';

    let script = document.createElement('script');
    script.setAttribute('src', '/model/v3d.js');
    document.head.appendChild(script);

    script = document.createElement('script');
    script.setAttribute('src', '/model/discovery 2.js');
    document.head.appendChild(script);

    numAnim1 = new CountUp('ppperhIrir', 2000, { useGrouping: false, duration: 1, separator: '' });

    numAnim2 = new CountUp('persecfoto', 1, { duration: 1, separator: '' });
    numAnim3 = new CountUp('perseccam2', 15, { duration: 1, separator: '' });
    numAnim4 = new CountUp('nonstop', 84, { duration: 1, separator: '' });

    self.$nextTick(() => {
      self.checkInfoView();
    });

    window.addEventListener('scroll', function(e) {
        self.checkInfoView();
    });

  },
  data() {
    return{
      started: false,
      model: {
        html: {
          col1: {
            EN: 'camera takes <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">32</span> photos per second',
            LAT: 'kamera napravi <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">32</span> fotografije u sekundi',
            СРБ: 'камера направи <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">32</span> фотографије у секунди',
            DE: 'Kamera macht <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">32</span> Fotos pro Sekunde',
          },
          col2: {
            EN: 'vehicles takes <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">500</span> photos per second',
            LAT: 'vozila napravi <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">500</span> fotografija u sekundi',
            СРБ: 'возила направи <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">500</span> фотографија у секунди',
            DE: 'Fahrzeuge machen <span style="font-family: \'Montserrat\', sans-serif; font-weight:500">500</span> Fotos pro Sekunde',
          }
          ,
          col3: {
            EN: 'working hours per week – continuous recording',
            LAT: 'radna sata nedeljno - snimanje bez prekida',
            СРБ: 'радна сата недељно - снимање без прекида',
            DE: 'Arbeitsstunden pro Woche – kontinuierliche Aufzeichnung',
          }
          ,
          col4: {
            EN: 'per hour observations',
            LAT: 'opažaja po satu',
            СРБ: 'опажаја по сату',
            DE: 'Beobachtungen pro Stunde',
          }
        },
      }
    }
  },
  methods: {
    checkInfoView() {
      if (this.isInViewport(document.getElementById('infoRow')) && !this.started) {
        this.started = true;
        document.getElementById("infoRow").classList.add("inview");

        numAnim2.start(() => {
          numAnim3.start(() => {
            numAnim4.start(() => {
              numAnim1.start(() => {

              });
            });
          });
        });

      }

    },
    isInViewport(elem) {
        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );
    },
  },
  watch: {
    lang: function() {

      if (this.lang === 'СРБ') {
        cam_text = '•	Четири инфрацрвенe камере високе резолуције, са циљем утврђивања садржаја регистарских таблица (све врсте карактера: слова и бројеви)';
        in_text = '•	индустријски рачунар за рад у отежаним радним условима<br>•	ГПС уређај<br>•	комуникациони модул за рад у мрежи мобилног oператора';
        back_text = '•	напајање са мотора возила и/или батерија<br>•	интегрисане хардверске компоненте заштићене од механичких и климатских услова, као и од електричних сметњи';
      } else if (this.lang === 'LAT') {
        cam_text = '•	Četiri infracrvene kamere visoke rezolucije, sa ciljem utvrđivanja sadržaja registarskih tablica (sve vrste karaktera: slova i brojevi)';
        in_text = '•	industrijski računar za rad u otežanim radnim uslovima<br>•	GPS uređaj<br>•	komunikacioni modul za rad u mreži mobilnog operatora';
        back_text = '•	napajanje sa motora vozila i/ili baterija<br>•	integrisane hardverske komponente zaštićene od mehaničkih i klimatskih uslova, kao i od električnih smetnji';
      } else if (this.lang === 'EN') {
        cam_text = '•	Four high-resolution infrared cameras, to determine the contents of license plates (all types of characters: letters and numbers)';
        in_text = '•	industrial computer for work in difficult working conditions<br>•	GPS device<br>•	communication module in the network of the mobile operator';
        back_text = '•	Powered by vehicle engines or batteries<br>•	integrated hardware components protected from mechanical and climatic conditions, as well as from electrical interference'
      } else if (this.lang === 'DE') {
        cam_text = '•	Vier hochauflösende Infrarotkameras, um den Inhalt von Nummernschildern zu ermitteln (alle Arten von Zeichen: Buchstaben und Zahlen)';
        in_text = '•	Industriecomputer für Arbeiten unter schwierigen Arbeitsbedingungen<br>•	GPS-Gerät<br>•	Kommunikationsbaustein im Netz des Mobilfunkbetreibers';
        back_text = '•	Antrieb durch Fahrzeugmotoren oder Batterien<br>•	integrierte Hardwarebaugruppen, die vor mechanische Gegebenheiten und Klimabedingungen sowie vor elektrischen Störungen geschützt sind'
      }

      window.dispatchEvent(new Event('resize'));

    }
  }
}

</script>