<template>
  <div class="header">
    <div class="header-container">
        <img @click.prevent="smoothScroll('hero');" class="logo-image" src="../assets/logo.svg" alt="">
        <ul class="nav">
        <li><a class="active" href="#" @click.prevent="smoothScroll('hero');">{{header.pocetna[selectedLang]}}</a></li>
        <li><a href="#" @click.prevent="smoothScroll('model');">{{header.statistika[selectedLang]}}</a></li>
        <li><a href="#" @click.prevent="smoothScroll('biginfo');">{{header.intervjui[selectedLang]}}</a></li>
        <li><a href="#" @click.prevent="smoothScroll('videos');">{{header.rezultati[selectedLang]}}</a></li>
        <li><a href="#" @click.prevent="smoothScroll('info');">{{header.kontakt[selectedLang]}}</a></li>
      </ul>
      <div class="social-media">
        <a :class="['mr-3', 'lang', {'open-menu': showLang}]" @click.prevent="showLang = !showLang" href="#">
          <span class="active">{{selectedLang}} <img class="chev" src="../assets/chevron-down.png" alt=""></span>
          <span class="not-selected-lang" v-if="selectedLang!='СРБ'" @click="$emit('lang-change', 'СРБ');selectedLang='СРБ'">СРБ</span>
          <span class="not-selected-lang" v-if="selectedLang!='LAT'" @click="$emit('lang-change', 'LAT');selectedLang='LAT'">LAT</span>
          <span class="not-selected-lang" v-if="selectedLang!='EN'" @click="$emit('lang-change', 'EN');selectedLang='EN'">EN</span>
          <span class="not-selected-lang" v-if="selectedLang!='DE'" @click="$emit('lang-change', 'DE');selectedLang='DE'">DE</span>
        </a>
        <!-- <a target="_blank" href="https://www.facebook.com/GradBeograd"><img src="../assets/fbr.svg" alt=""></a>
        <a target="_blank" href="https://www.youtube.com/user/parkingservis/videos"><img src="../assets/youtube.png" alt=""></a> -->
        <!--a trget="_blank" href="#"><img src="../assets/inr.svg" alt=""></a>
        <a trget="_blank" href="#"><img src="../assets/twr.svg" alt=""></a-->
      </div>
    </div>
    <div :class="['header__nav', {'opened': open}, {'black': !isHero}]">
      <div :class="['toggle', {'clicked': open}]" @click="open=!open">
        <div class="bar"></div>
      </div>
    </div>
    <div :class="['menu', {'collaps': open===false}, {'expand': open===true} ]">
      <div class="nav-hold">
        <div class="left-menu"></div>
        <ul class="navigation text-center">
          <li class="mtop"><a :class="{'active': positions.hero}" href="" @click.prevent="smoothScroll('hero');open=false">{{header.pocetna[selectedLang]}}</a></li>
          <li><a :class="{'active': positions.model}" href="" @click.prevent="smoothScroll('model');open=false">{{header.statistika[selectedLang]}}</a></li>
          <li><a :class="{'active': positions.videos}" href="" @click.prevent="smoothScroll('biginfo');open=false">{{header.intervjui[selectedLang]}}</a></li>
          <li><a :class="{'active': positions.digitalizacija}" href="" @click.prevent="smoothScroll('videos');open=false">{{header.rezultati[selectedLang]}}</a></li>
          <li><a :class="{'active': positions.info}" href="" @click.prevent="smoothScroll('info');open=false">{{header.kontakt[selectedLang]}}</a></li>
          <div class="d-flex langchange">
            <span :class="{'active': selectedLang=='СРБ'}" @click="$emit('lang-change', 'СРБ');selectedLang='СРБ'">СРБ</span>
            <span :class="{'active': selectedLang=='LAT'}" @click="$emit('lang-change', 'LAT');selectedLang='LAT'">LAT</span>
            <span :class="{'active': selectedLang=='EN'}" @click="$emit('lang-change', 'EN');selectedLang='EN'">EN</span>
            <span :class="{'active': selectedLang=='DE'}" @click="$emit('lang-change', 'DE');selectedLang='DE'">DE</span>
          </div>
        </ul>
        <div class="social-hero text-center">
          <!-- <a class="mr-3" target="_blank" href="https://www.facebook.com/GradBeograd"><img src="/images/facebook.svg" alt=""></a>
          <a class="mr-3" target="_blank" href="https://www.youtube.com/user/parkingservis/videos"><img src="/images/youtube.png" alt=""></a> -->
          <!--a class="mr-3" target="_blank" href="#"><img src="/images/instagram.svg" alt=""></a>
          <a target="_blank" href="#"><img src="/images/twiter.svg" alt=""></a-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: [],
  data() {
    return {
      header: {
        pocetna: {
          EN: 'Home',
          DE: 'Zuhause',
          LAT: 'POČETNA',
          СРБ: 'ПОЧЕТНА',
        },
        statistika: {
          EN: 'Statistics',
          DE: 'Statistiken',
          LAT: 'statistika',
          СРБ: 'Cтатистика',
        },
        intervjui: {
          EN: 'System',
          DE: 'System',
          LAT: 'Sistem',
          СРБ: 'СИСТЕМ',
        },
        rezultati: {
          EN: 'Video',
          DE: 'Video',
          LAT: 'VIDEO',
          СРБ: 'ВИДЕО',
        },
        kontakt: {
          EN: 'Contact',
          DE: 'Kontakt',
          LAT: 'kontakt',
          СРБ: 'контакт',
        }
      },
      showLang: false,
      repos: 0,
      open: null,
      positions: {
          hero: false,
          model: false,
          potencial: false,
          videos: false,
          info:false,
      },
      isHero: true,
      selectedLang: 'СРБ'
    }
  },
  mounted() {

    let self = this;

    setTimeout(() => {
      self.init();
    }, 0);

    this.$nextTick(() => {
      self.checkWhoInViewport();
      self.checkHeroVP();
    })
    window.onscroll = function() {
      self.checkWhoInViewport();
      self.checkHeroVP();
    };
  },
  methods: {
    init() {
      if (this.$route.params && this.$route.params.lang) {
        if (this.$route.params.lang === 'en') {
          this.selectedLang = 'EN';
          this.$emit('lang-change', 'EN');
        }
        if (this.$route.params.lang === 'srb') {
          this.selectedLang = 'LAT';
          this.$emit('lang-change', 'LAT');
        }
        if (this.$route.params.lang === 'cir') {
          this.selectedLang = 'СРБ';
          this.$emit('lang-change', 'СРБ');
        }
        if (this.$route.params.lang === 'de') {
          this.selectedLang = 'DE';
          this.$emit('lang-change', 'DE');
        }
      }
    },
    checkHeroVP() {
      if (this.isInViewportHero(document.getElementById('hero'))) {
        this.isHero = false;
      } else {
        this.isHero = true;
      }
    },
    checkWhoInViewport() {

      let i;
      this.changed = null;

      for (i in this.positions) {

        if (this.isInViewport(document.getElementById(i))) {
          this.positions[i] = true;
          document.getElementById(i).classList.add('active');
          document.getElementById(i).classList.add('active-view');
          this.changed = i;
          break;
        }
      }

      for (i in this.positions) {

        if (this.changed) {
            if (this.changed !== i) {
              this.positions[i] = false;
              document.getElementById(i).classList.remove('active-view');
              //document.getElementById(i).classList.remove('active');
            }
        }

      }

    },
    isInViewportHero(elem) {
      if (!elem) return 0;
      var bounding = elem.getBoundingClientRect();
      return ( bounding.bottom < 0 );
    },
    isInViewport(elem) {
      if (!elem) return 0;
      var bounding = elem.getBoundingClientRect();
      return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          (bounding.top+bounding.top/2) <= (window.innerHeight || document.documentElement.clientHeight)
      );
    },
    currentYPosition() {
        // Firefox, Chrome, Opera, Safari
        if (self.pageYOffset) return self.pageYOffset;
        // Internet Explorer 6 - standards mode
        if (document.documentElement && document.documentElement.scrollTop)
            return document.documentElement.scrollTop;
        // Internet Explorer 6, 7 and 8
        if (document.body.scrollTop) return document.body.scrollTop;
        return 0;
    },
    elmYPosition(eID) {
        var elm = document.getElementById(eID);
        var y = elm.offsetTop-this.repos;
        var node = elm;
        while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
        } return y;
    },
    smoothScroll(eID) {
      let i;
      this.menu = false;
      var startY = this.currentYPosition();
      var stopY = this.elmYPosition(eID);
      var distance = stopY > startY ? stopY - startY : startY - stopY;
      if (distance < 100) {
          scrollTo(0, stopY); return;
      }
      var speed = Math.round(distance / 10);
      if (speed >= 20) speed = 20;
      var step = Math.round(distance / 25);
      var leapY = stopY > startY ? startY + step : startY - step;
      var timer = 0;
      if (stopY > startY) {
          for (i=startY; i<stopY; i+=step ) {
              setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
              leapY += step; if (leapY > stopY) leapY = stopY; timer++;
          } return;
      }
      for (i=startY; i>stopY; i-=step ) {
          setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
          leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
      }
    }
  },
}
</script>