<template>
  <div class="new-section">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <h3 class="text-uppercase" v-html="translations.top.col1[lang]"></h3>
            </div>
            <div class="col-12 col-md-8">
                <p v-html="translations.bottom.col1[lang]"></p>
            </div>
        </div>
        <div class="line-section-new"></div>
        <div class="row">
            <div class="col-12 col-md-4">
                <h3 class="text-uppercase" v-html="translations.top.col2[lang]"></h3>
            </div>
            <div class="col-12 col-md-8">
                <p v-html="translations.bottom.col2[lang]"></p>
            </div>
        </div>
        <div class="line-section-new"></div>
        <div class="row">
            <div class="col-12 col-md-4">
                <h3 class="text-uppercase" v-html="translations.top.col3[lang]"></h3>
            </div>
            <div class="col-12 col-md-8">
                <p v-html="translations.bottom.col3[lang]"></p>
            </div>
        </div>
        <div class="line-section-new"></div>
        <div class="row">
            <div class="col-12 col-md-4">
                <h3 class="text-uppercase" v-html="translations.top.col4[lang]"></h3>
            </div>
            <div class="col-12 col-md-8">
                <p v-html="translations.bottom.col4[lang]"></p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'NewSection',
  props: ['lang'],
  mounted() {
  },
  data() {
    return{
        translations: {
            top: {
                col1: {
                    EN: 'The preventive role <BR>of the system',
                    LAT: 'Preventivna<br>uloga sistema',
                    СРБ: 'Превентивна<br>улога система',
                    DE: 'DIE PRÄVENTIVE ROLLE <BR>DES SYSTEMS'
                },
                col2: {
                    EN: 'Streets without <br> stoppage and crowd',
                    LAT: 'Ulice bez<br> zastoja i gužvi',
                    СРБ: 'Улице без<br> застоја и гужви',
                    DE: 'STRAßEN OHNE <br>SPERRUNG UND MENGE'
                },
                col3: {
                    EN: 'More efficient <br> control',
                    LAT: 'Efikasnija<br> kontrola',
                    СРБ: 'Ефикаснија<br> контрола',
                    DE: 'EFFIZIENTERE <br>STEUERUNG'
                },
                col4: {
                    EN: 'Smart <br> city',
                    LAT: 'Pametan<br> grad',
                    СРБ: 'Паметан<br> град',
                    DE: 'SMART <br> CITY'
                }
            },
            bottom: {
                col1: {
                    EN: 'Vehicles of the "Oko sokolovo" system circle through the central Belgrade city streets and demotivate drivers to park their vehicles improperly.',
                    LAT: 'Vozila Sistema „Oko sokolovo“ kruže centralnim gradskim ulicama i demotivišu vozače da svoja vozila nepropisno <br>parkiraju.',
                    СРБ: 'Возила Система „Око соколово“ круже централним градским улицама и демотивишу возаче да своја возила непрописно <br>паркирају.',
                    DE: 'Fahrzeuge des Systems “Oko sokolovo” fahren durch die Belgrader zentralen Straßen um und demotivieren die Fahrer, ihre Fahrzeuge unzulässig zu parken.'
                },
                col2: {
                    EN: 'Drivers park less illegally, traffic jams are reduced due to improper parking of vehicles on the road, traffic flow is throughly facilitated.',
                    LAT: 'Vozači se manje nepropisno parkiraju, smanjene su saobraćajne gužve zbog nepropisnog ostavljanja vozila na kolovozu, olakšan je protok saobraćaja.',
                    СРБ: 'Возачи се мање непрописно паркирају, смањене су саобраћајне гужве због непрописног остављања возила на коловозу, олакшан је проток саобраћаја.',
                    DE: 'Fahrer parken weniger widerrechtlich, die Verkehrsstaue werden durch unzulässiges Parken auf der Straße reduziert, der Verkehrsfluss wird gründlich erleichtert.'
                },
                col3: {
                    EN: 'Drivers pay for parking more regularly and respect the time limit in the zones.',
                    LAT: 'Vozači urednije plaćaju parkiranje i poštuju vremensko ograničenje u zonama.',
                    СРБ: 'Возачи уредније плаћају паркирање и поштују временско ограничење у зонама.',
                    DE: 'Fahrer zahlen regelmäßiger für das Parken und halten das Zeitlimit in den Zonen ein.'
                },
                col4: {
                    EN: '"Oko sokolovo" is the first innovative system in the region that contributes to smart solutions for traffic regulation in Belgrade.',
                    LAT: '„Oko sokolovo” je prvi inovativni sistem koji doprinosi pametnim rešenjima za regulisanje saobraćaja u Beogradu.',
                    СРБ: '„Око соколово” је први иновативни систем који доприноси паметним решењима за регулисање саобраћаја у Београду.',
                    DE: '“Oko sokolovo” ist das erste innovative System in der Region, das zu intelligenten Lösungen für die Verkehrsregelung in Belgrad beiträgt.'
                }
            }
        }
    }
  },
  methods: {
  },
}

</script>