<template>
  <div id="biginfo" class="biginfo shadow-main">
    <div class="najpouzdaniji">
      <div class="content-background d-none d-sm-none d-md-block display-only-desktop">
          <h2 v-html="translate.title[lang]"></h2>
          <p class="">{{translate.line1[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
        </div>
    </div>
    <div class="content-background-mobile-najpouzdaniji display-only-phone">
          <h2 v-html="translate.title[lang]"></h2>
          <p class="">{{translate.line1[lang]}}</p>
          <p>{{translate.line2[lang]}}</p>
        </div>
  </div>
</template>

<script>

export default {
  name: 'Najpouzdaniji',
  props: ['lang'],
  data() {
    return {
      translate: {
        title: {
          EN: 'THE MOST RELIABLE SYSTEM<br><span>to solve traffic problems</span>',
          LAT: 'NAJPOUZDANIJI SISTEM<br><span>za rešavanje problema u saobraćaju</span>',
          СРБ: 'НАЈПОУЗДАНИЈИ СИСТЕМ<br><span>за решавање проблема у саобраћају</span>',
          DE: 'DAS ZUVERLÄSSIGSTE SYSTEM<br><span>um Verkehrsprobleme zu lösen</span>'
        },
        line1: {
          EN: 'The basis of the entire system is the Geographic Information System (GIS) - a map of all streets in the city, roads, sidewalks, traffic signs, traffic lights, pedestrian crossings, parking spaces, and all the other important elements.',
          LAT: 'Osnova celog sistema je Geografsko-informacioni sistem (GIS) – mapa svih ulica u gradu, kolovoza, trotoara, saobraćajnih znakova, semafora, pešačkih prelaza, parking mesta i drugih važnih elemenata.',
          СРБ: 'Основа целог система је Географско-информациони систем (ГИС) – мапа свих улица у граду, коловоза, тротоара, саобраћајних знакова, семафора, пешачких прелаза, паркинг места и других важних елемената.',
          DE: 'Die Grundlage des gesamten Systems ist das Geografische Informationssystem (GIS) – eine Karte aller Straßen in der Stadt, Wege, Bürgersteige, Straßenschilder, Ampeln, Zebrastreifen, Parkplätze und alle anderen wichtigen Elemente.'
        },
        line2: {
          EN: 'The system includes 18 special vehicles with technological upgrades, industrial equipment (from computers to cameras), reliable support (bumps, braking), continuous shooting (84 working hours per week) even in the most extreme weather conditions.',
          LAT: 'Sistem obuhvata 18 specijalnih vozila sa nadogradnjom, industrijsku opremu (od računara do kamera), pouzdano oslanjanje (neravnine, kočenja), snimanje bez prekida (84 radna sata nedeljno) i funkcionisanje i u najekstremnijim vremenskim uslovima.',
          СРБ: 'Систем обухвата 18 специјалних возила са надоградњом, индустријску опрему (од рачунара до камера), поуздано ослањање (неравнине, кочења), снимање без прекида (84 радна сата недељно) и функционисање и у најекстремнијим временским условима.',
          DE: 'Das System umfasst 18 Spezialfahrzeuge mit technologischen Aufrüstung, Industrieausrüstungen (von Computern bis zu Kameras), zuverlässige Unterstützung (Bodenwellen, Bremsen) und kontinuierlichen Aufnahmen (84 Arbeitsstunden pro Woche) auch unter extremsten Wetterbedingungen.'
        }
      }
    }
  },
  mounted() {
    let self = this;
    this.$nextTick(() => {
      this.checkInfoView();
    });
    window.addEventListener('scroll', function(e) {
        self.checkInfoView();
    });
  },
  methods: {
    checkInfoView() {
      if (this.isInViewport(document.getElementById('biginfo'))) {

        document.getElementById("biginfo").classList.add("active");

      }
    },
    isInViewport(elem) {

        var bounding = elem.getBoundingClientRect();

        return (
            bounding.top >= 0 && bounding.top+100 <= (window.innerHeight || document.documentElement.clientHeight)
        );

    }
  }
}

</script>